@media only screen and (min-width: 962px) {
    .border-between > [class*='col-']:before {
      background: #000;
      bottom: 0;
      content: ' ';
      left: 2px;
      position: absolute;
      width: 1px;
      top: 0;
    }
    .border-between > [class*='col-']:first-child:before {
      display: none;
    }
}

.menu-toggle {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

.menu-toggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
.menu-toggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(.77,.2,.05,1.0),
              background 0.5s cubic-bezier(.77,.2,.05,1.0),
              opacity 0.55s ease;
}

.menu-toggle span:first-child {
  transform-origin: 0% 0%;
}

.menu-toggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.menu-toggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
.menu-toggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(.2, .2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.menu-toggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
.menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(.77,.2,.05,1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
.menu-toggle input:checked ~ ul {
  transform: none;
}

.table70 {
  width: 100% !important;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1100px) {
  .table70{
    width: 70% !important;
  }
}
