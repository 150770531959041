.app {
  text-align: center;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", "Roboto", "Open Sans", "Themify", sans-serif;
  padding-bottom: 25px;
}

@-moz-document url-prefix() {
  .app {
    margin-bottom: 100px;
  }
}

@media print {
  .results-div {
    display: block;
  }
  .ecobar-layer-1,
  .nav-panel,
  .nav-panel-bkg,
  .pub-select,
  .pub-descrip,
  .pub-download,
  .pub-query,
  .filter-buttons,
  #bottom {
    display: none;
  }

  body {
    /* Or specify a table class */
    overflow-y: visible !important;
    overflow-x: visible !important;
  }
}

.bg-gray {
  background-color: #535858;
  border-color: #535858;
  color: #fff;
}

.bg-secondary-dark {
  background-color: #444;
  border-color: #444;
  color: #fff;
}

.bg-gray-light {
  background-color: #969c9c;
  border-color: #969c9c;
  color: #ffffff;
}

.bg-gray-lighter {
  background-color: #e7e7e7;
  border-color: #ddd;
  color: #000;
}

.bg-white {
  background-color: #fff;
  border-color: #ddd;
  color: #000;
}

.results-div {
  max-height: 900px;
  overflow: auto;
}

.pub-download-bg {
  background-color: #f8f8f8;
  color: #000;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 11px;
}

.breadcrumb-div {
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 1px;
  padding: 0 0;
  list-style: none;
}
.select-overflow {
  overflow: visible;
}
.row {
  margin: auto;
}

.no-display {
  display: none;
}

.display {
  display: block;
}

.left-align {
  text-align: left;
}

.center-align {
  text-align: center;
}

h3 {
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.1;
  color: inherit;
}

h4 {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

h5 {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  letter-spacing: 2px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}

h6 {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 18px;
  margin-top: 18px;
}

.h7 {
  font-weight: 600;
  font-size: 16px;
  color: #666;
  letter-spacing: 1px;
  margin-top: 18px;
  margin-bottom: 18px;
}

ul {
  margin-top: 0;
  margin-bottom: 24px;
  padding: 0;
  list-style: none;
}

.bullets {
  list-style: disc outside none;
  padding-left: 1em;
}

.narrow-list {
  line-height: 1.5;
  font-size: 90%;
}

li {
  display: list-item;
}

option:disabled {
  color: #969c9c;
}

section {
  position: relative;
  overflow: hidden;
  display: block;
  padding: 0 24px;
}
@media all and (max-width: 768px) {
  section {
    padding: 80px 0;
  }
}

.m0 {
  margin: 0 0 0 0 !important;
}

.mt0 {
  margin-top: 0px;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.ml15 {
  margin-left: 20px;
}

.mr15 {
  margin-right: 15px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb24 {
  margin-bottom: 24px;
}

.p10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pb10 {
  padding-bottom: 10px;
  padding-top: 50px;
}

.pr0 {
  padding-right: 0px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pl0 {
  padding-left: 0px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.p15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.p32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.pb32 {
  padding-bottom: 32px;
}

.p0 {
  padding: 0px 0px;
}

.navbar {
  margin-bottom: 0px;
}

.nav-panel {
  padding-top: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid #999999 !important;
  background: #f8f8f8;
}
.nav-panel-bkg {
  padding-top: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid #999999 !important;
  background: #f8f8f8;
  background-image: url("../imgs/worldmap_blue.png");
  background-repeat: no-repeat;
  background-position: center;
}

.page-title {
  letter-spacing: 3px;
  margin-right: -6px;
  text-align: center;
  font-weight: 600 !important;
  padding: 35px 0;
}

.window-icon {
  padding-bottom: 5px;
}

.pub-query {
  padding-bottom: 25px;
}

div {
  padding-top: 1px;
}

.text-upper {
  text-transform: uppercase;
}

.text-normal {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.thin {
  padding: 2px 8px !important;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
}

.hover-white:hover {
  color: #fff;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-color: #fff;
}

.btn:hover,
.btn:active,
.btn.btn-xs:hover,
.btn.btn-xs:active {
  /*background: #2d959c;*/
  background: #005ab5;
  color: #fff;
  box-shadow: none;
}

.btn.btn-green:hover {
  background-color: #fff;
  /*color: #2d959c;*/
  color: #005ab5;
}

.btn.btn-white:hover {
  background-color: #fff;
  color: #666;
  box-shadow: none;
}

.btn {
  box-sizing: border-box;
  font-family: "Raleway", "Roboto", "Open Sans", "Themify", sans-serif;
  /*border: 2px solid #2d959c;*/
  border: 2px solid #005ab5;
  padding: 0 26px;
  height: 40px;
  min-width: 150px;
  line-height: 36px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 0;
  /*color: #2d959c;*/
  color: #005ab5;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 8px;
  margin-bottom: 24px;
}

.btn.disabled {
  border: 2px solid #999999;
  color: #999999;
}

.btn-ntm {
  min-width: 70px;
  background-color: #fff;
  text-decoration: none;
  margin: 0px 0px;
  padding: 0px 20px;
}

.btn-pub {
  text-transform: none;
  font-size: 85% !important;
}

.btn-ntm-pub {
  padding: 0 10px;
}

.btn-xs {
  font-size: 80%;
  min-width: 55px;
  padding: 2px 6px;
  border-width: 0;
  background: #ddd;
  color: #000000;
  margin: 0 4px 8px 0;
}

.btn-green {
  /*background-color: #2d959c;*/
  background-color: #005ab5;
  color: #fff;
  text-align: left;
  text-transform: none;
  font-size: 12px !important;
  font-weight: 300;
  margin-bottom: 0px !important;
}

.btn-white {
  color: #fff;
  border-color: #fff;
  background-color: inherit;
  border: 2px solid;
}

@media only screen and (min-width: 600px) {
  .btn,
  .btn.btn-xs {
    min-width: 70px;
    font-size: 75%;
    overflow: hidden;
  }
}

@media only screen and (min-width: 990px) {
  .btn-home {
    font-size: 65%;
    overflow: visible;
  }
}

@media only screen and (min-width: 1200px) {
  .btn-home {
    font-size: 75%;
    overflow: visible;
  }
}

.vol-btn {
  font-size: 10px;
  text-transform: none;
  letter-spacing: 0px;
  margin-right: 15px;
  margin-bottom: 10px;
  line-height: 34px;
  height: 34px;
  padding: 0 10px;
}

.query-btn {
  font-size: 10px;
  text-transform: none;
  letter-spacing: 0px;
}

.btn-asam {
  padding: 2px 12px !important;
  letter-spacing: 0.5px !important;
  margin-bottom: 5px;
}

.form-control {
  border-radius: 0px;
}

.query-panel .btn,
.query-panel .btn.btn-xs {
  box-sizing: border-box;
  font-size: 85%;
  height: 34px;
  padding: 2px 8px;
  min-width: 100px;
  line-height: 27px;
}

.query-label {
  text-transform: uppercase;
  /*color: #2d959c !important;*/
  color: #005ab5 !important;
  font-weight: 400;
  text-align: left !important;
  font-size: 75%;
}

.view-ntm-label {
  text-transform: uppercase;
  text-align: center;
  font-size: 80%;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.underline {
  text-decoration: underline;
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  text-decoration: none;
  outline: none;
}

a {
  font-weight: 600;
  color: #005ab5;
  /*color: #2d959c;*/
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.link-underlined {
  font-weight: 400;
  text-decoration-line: underline;
}

.table a {
  font-weight: 600 !important;
}
@media all and (min-width: 992px) {
  .m15 {
    margin-right: 5px;
  }
}
@media all and (min-width: 768px) {
  .m15 {
    margin-right: 5px;
  }

  .query-label {
    font-size: 70%;
  }
}
@media all and (max-width: 768px) {
  p.lead {
    font-size: 13px !important;
    line-height: 24px !important;
  }
}

p.lead {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

p {
  margin-bottom: 24px;
  margin-top: 0;
  font-weight: 400;
  display: block;
}

.filter-buttons {
  position: relative;
  top: 26px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.width70 {
  width: 70px;
}

.width50 {
  width: 50px;
}

.half-width {
  width: 50%;
}

.fourty-width {
  width: 40%;
}

.quarter-width {
  width: 25%;
}

.tenth-width {
  width: 15%;
}

.three-quarter-width {
  width: 75%;
}

.full-width {
  width: 100%;
}

.width160 {
  width: 160px;
}

.width500 {
  width: 500px !important;
}

.auto-margin {
  margin: auto;
}

.inline {
  display: inline;
}

.login {
  margin: 0;
  line-height: 40px;
}

.bordercell {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.invisible-box {
  border: 0px solid black;
  box-shadow: #000 0 0;
  background-color: inherit;
  pointer-events: none;
}

.border-box {
  border: 1px solid #ddd;
  border-radius: 1px;
}

.msi-header {
  display: flex;
  justify-content: space-between;
}

.msi-header .header-links {
  justify-content: center;
  display: flex;
  flex-direction: column;
}



/*nav bar buttons were too wide*/
@media only screen and (min-width: 600px) {
  .btn,
  .btn.btn-xs {
    min-width: 40px;
  }
}

.modal {
  padding-top: 100px;
  z-index: 1;
}

.modal-body {
  overflow: auto;
  max-height: 300px;
}

#embeddedHtml > table > tbody > tr > td > pre {
  border: none !important;
  background-color: #fff;
  text-align: left;
}

#ecobar-icons-list {
  display: none !important;
}
#ecobar-footer {
  display: none !important;
}
/* Hide HTML5 Up and Down arrows in input number boxes. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Hide HTML5 Up and Down arrows in input number boxes. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.big-char {
  font-size: 18px;
  font-weight: 600;
}

.misc-pd-menu li {
  padding-right: 0px;
}
.misc-pd-menu li:last-child {
  padding-right: 5px;
}
.misc-pd-menu li:before {
  content: "\00a0\2022\00a0\00a0";
  color: rgba(0, 0, 0, 0.25);
  font-size: 11px;
}
.misc-pd-menu li:first-child:before {
  content: "";
}
.misc-pd-menu li > a {
  text-transform: uppercase;
  font-weight: 400;
}
.misc-pd-menu li {
  background-color: #fff;
}

.prod-card-div {
  margin-bottom: 10px;
}
.product-card {
  text-align: left;
  margin-top: 10px;
  padding: 15px 15px;
  border: 1.5px solid #e7e7e7;
  color: #666;
}
.product-card > p {
  font-size: 90%;
  margin-bottom: 0px;
}
.prodCardHdr {
  font-size: 15px !important;
  font-weight: bold;
}

.has-warning {
  color: #8a6d3b;
}

.special-para-btn {
  width: 100%;
  margin-bottom: 10px !important;
}

/*FAQ and DidYouKnow Button Styling*/
.btn-new {
  text-align: left;
  font-size: 12pt;
}

.border-around {
  border: 1px solid #999999;
}

.change-color {
  color: #000000;
  font-weight: 500;
}

/*Nautical Calculator Tabfont*/
.indent {
  text-indent: 50px;
}

.calc-link {
  color: #000000;
  font-weight: 500;
  text-decoration: underline;
}

.calc-body-link {
  line-height: 2.3;
}

#banner:hover:after {
  background: hsla(0, 0%, 0%, 0.8);
  border-radius: 3px;
  color: #f6f6f6;
  content: "Click to dismiss";
  font: bold 12px/30px sans-serif;
  height: 30px;
  left: 50%;
  margin-left: -60px;
  position: absolute;
  text-align: center;
  top: 50px;
  width: 120px;
}

#banner:hover:before {
  border-bottom: 10px solid hsla(0, 0%, 0%, 0.8);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: 40px;
  width: 0;
}

#banner:target {
  display: none;
}

.banner {
  animation: alert 1s ease forwards;
  background-color: #c4453c;
  background-image: linear-gradient(
    135deg,
    transparent,
    transparent 25%,
    hsla(0, 0%, 0%, 0.1) 25%,
    hsla(0, 0%, 0%, 0.1) 50%,
    transparent 50%,
    transparent 75%,
    hsla(0, 0%, 0%, 0.1) 75%,
    hsla(0, 0%, 0%, 0.1)
  );
  background-size: 20px 20px;
  box-shadow: 0 5px 0 hsla(0, 0%, 0%, 0.1);
  color: #f6f6f6;
  display: block;
  font: bold 16px/40px sans-serif;
  height: 40px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: -45px;
  width: 100%;
}

@keyframes alert {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
  }
}

.query-dir {
  height: 34px;
  line-height: 3;
}

.gray-light-box {
  margin: 5px 5px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 30px;
  border: 1px solid #999999 !important;
  background: rgba(248, 248, 248, 0.9);
}

.bg-gray-box {
  background-color: #e7e7e7;
  border-color: #ddd;
  color: #000;
}
body {
  padding-bottom: 70px;
}

.msifooter {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: #282828;
  width: 100%;
  min-height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.msifootertxt {
  padding-top: 20px;
}
.ngafooterlogo {
  padding-top: 5px;
  bottom: 0;
  font-size: 30px;
  font-weight: 500;
  font-family: "Raleway", "Roboto", "Open Sans", "Themify", sans-serif;
  color: #969c9c;
}

.classificationblock {
  float: none;
  clear: both;
  display: block;
  background-color: #3a3b3c;
  color: #e9e9e9;
  overflow: hidden;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 18px;
  height: 20px;
  max-height: 20px;
  padding: 0 2px;
  margin: 0;
  font-size: 10px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  letter-spacing: 0;
  z-index: 100;
}

.link-white {
  color: #ffffff !important;
}

.report-Column {
  width: 65%;
}

.form-alert {
  color: red;
}

input[type="file"] {
  display: inline-block;
}

.no-borders {
  border: 0px solid black !important;
}

.homebkg {
  background: #ffffff;
  background-image: url("../imgs/wmap.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 0%;
  min-height: 600px;
}

.yellowHL {
  background-color: yellow;
}

.input-group {
  display: inline-table;
}

.disabled-text {
  color: #ddd;
}

.summ-corr-btn {
  margin-bottom: 3px;
  min-width: 100px !important;
  height: 34px;
  padding: 2px 8px;
  min-width: 100px;
  line-height: 27px;
}