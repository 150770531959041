.query-title {
  padding: 5px !important;
  font-weight: bold !important;
  font-variant: small-caps !important;
  font-size: 13pt;
  text-align: center;
  font-family: Times, serif !important;
}

.no-border {
  border: none !important;
}

.no-top-border {
  border-top: none !important;
}

.table-logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
}

.valignTop {
  vertical-align: top;
}

.not-found {
  box-sizing: border-box;
  margin: auto;
  margin-top: 40px;
  width: 50%;
  margin-bottom: 30px;
  background: #fff;
  padding: 20px;
  border: 1px solid #154e7d;
  text-align: center;
}

.case-capitalize {
  text-transform: capitalize;
}

.port-title {
  padding: 5px;
  font-size: 13pt;
  text-align: center;
  font-family: Times, serif;
}

td span.uscgll {
  margin-right: 60px;
}

td span:first-child.uscgll {
  float: left;
}

td.port {
  text-align: left;
  padding-left: 30px;
}

thead.port {
  width: 70%;
}

table.port {
  border-style: solid;
  border-width: 2px;
  width: 70%;
}

#frequency,
#times,
#broadcastNature,
#hours,
#characteristicText,
#structureText,
#structure {
  white-space: normal;
  word-wrap: break-word;
}

@media only screen and (min-width: 900px) {
  #rxPosition,
  #txPosition {
    white-space: nowrap;
  }
}

#position {
  white-space: pre;
}

#characteristicRemarks,
#structureText,
#structure {
  width: 20%;
}

#V5characteristicRemarks,
#remarks,
#hydro-title {
  width: 40%;
  white-space: pre-wrap;
}

#latlong {
  width: 120px;
  white-space: pre-wrap;
}

#aidNumber,
#name,
#characteristicText,
#characteristicRemarks,
#heightFeet,
#height,
#range,
#searchTerms,
#transmitStation,
#structureTextLL,
#transmissionText {
  white-space: pre-wrap;
}

#searchTerms {
  word-wrap: break-word;
  max-width: 100px;
}

#structureUp,
#structure {
  width: 210px;
  white-space: pre-wrap;
}

.bwarn-data {
  border: none !important;
  background-color: #fff;
  max-width: 100%;
}

.content {
  white-space: pre-wrap;
}

p {
  margin-bottom: 0px;
}

.cancelled {
  color: red;
}
