body {
  margin: 0;
  padding: 0;  
}

.box1 {
  border: solid 1px red;
}

.box2 {
  border: solid 1px blue;
}

.box3 {
  border: solid 1px green;
}

.box4 {
  border: solid 1px purple;
}

.box5 {
  border: solid 1px orange;
}
