/* Warnings Section Styling */
section.warnings-section ul.narrow-list {
  line-height: 1.5;
}

section.warnings-section, section.warnings-section ul.narrow-list {
  line-height: 1.5;
}

section.warnings-section .btn {
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 85%;
  width: 100%;
  min-width: 100%;
  white-space: pre-line !important;
  height: auto;
  padding: 8px 8px;
} /* lets button line break appropriately on lower widths */

.table td, .table th {
  padding: 4px !important;
}

.table a {
  font-weight: 600 !important;
}

section.warnings-section .atlantic-pacific .btn {
  box-sizing: border-box;
  white-space: pre-line;
  padding: 4px 8px;
  height: auto;
  font-size: 85%;
  max-width: 99%;
  margin: 0 12px 12px 3px;
}

/* Warnings Section Styling -- Adds Border between columns at higher resolutions */
@media only screen and (min-width: 962px) {
  .border-between > [class*='col-']:before {
    background: #000 !important;
  }
  .border-between > [class*='col-']:first-child:before {
    display: none;
  }
  section.warnings-section div.atlantic-pacific div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

thead {
  text-transform: uppercase;
  font-weight: 400;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

td, th {
  line-height: 24px !important;
  padding: 4px !important;
}

.warnings-table {
  color: #444;
  text-align: center;
}

.current-warnings .btn {
  box-sizing: border-box;
  line-height: 1.5;
  font-size: 85%;
  width: 100%;
  min-width: 100%;
  white-space: pre-line !important;
  height: auto;
  padding: 8px 8px;
}

#report-submission{
  margin-top: 24px;
}